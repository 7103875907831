'use es6';

import { connect } from 'react-redux';
import { getLatestWidgetData } from '../../widget-data/selectors/getLatestWidgetData';
import { getAssignedResponderInWidget } from '../../responders/selectors/getAssignedResponderInWidget';
import { clickedMeetingsLink } from '../../actions/MeetingsActions/clickedMeetingsLink';
import MeetingsLinkButton from '../components/MeetingsLinkButton';
import { shouldShowMeetingsLink } from '../selectors/shouldShowMeetingsLink';
export default connect(function (state) {
  return {
    assignedResponder: getAssignedResponderInWidget(state),
    shouldShowMeetingsLink: shouldShowMeetingsLink(state),
    widgetData: getLatestWidgetData(state)
  };
}, {
  clickedMeetingsLink: clickedMeetingsLink
})(MeetingsLinkButton);