'use es6';

import { createAction } from 'flux-actions';
import * as ActionTypes from '../../constants/VisitorActionTypes';
import { trackInteraction } from '../../usage-tracking/actions/trackInteraction';
export var clickedMeetingsLinkAction = createAction(ActionTypes.CLICKED_MEETINGS_LINK);
export function clickedMeetingsLink() {
  return function (dispatch) {
    dispatch(clickedMeetingsLinkAction());
    dispatch(trackInteraction('widget-interaction', {
      action: 'clicked meeting link'
    }));
  };
}