'use es6';

import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';

var cancelDragEvent = function cancelDragEvent(e) {
  e.preventDefault();
  e.stopPropagation();
};

var AttachmentUploadDragAndDropWrapper = function AttachmentUploadDragAndDropWrapper(_ref) {
  var children = _ref.children,
      disabled = _ref.disabled,
      ignoreDropWhenDefaultPrevented = _ref.ignoreDropWhenDefaultPrevented,
      onDropFiles = _ref.onDropFiles;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isOver = _useState2[0],
      setIsOver = _useState2[1];

  var isOverRef = useRef(null);
  var onDragEnter = useCallback(function (e) {
    e.preventDefault();
    isOverRef.current = e.target;
    setIsOver(true);
  }, []);
  var onDragOver = useCallback(cancelDragEvent, []);
  var onDragLeave = useCallback(function (e) {
    if (isOverRef.current === e.target) {
      isOverRef.current = null;
      setIsOver(false);
    }
  }, []);
  var onDrop = useCallback(function (e) {
    if (ignoreDropWhenDefaultPrevented && e.isDefaultPrevented()) {
      setIsOver(false);
      return;
    }

    var files = e.dataTransfer ? e.dataTransfer.files : [];

    if (files && files.length) {
      e.preventDefault();
      onDropFiles(files);
      setIsOver(false);
    }
  }, [onDropFiles, ignoreDropWhenDefaultPrevented]);

  if (disabled) {
    return children({
      isOver: false,
      onDragOver: cancelDragEvent,
      onDrop: cancelDragEvent
    });
  }

  return children({
    isOver: isOver,
    onDrop: onDrop,
    onDragEnter: onDragEnter,
    onDragOver: onDragOver,
    onDragLeave: onDragLeave
  });
};

AttachmentUploadDragAndDropWrapper.displayName = 'AttachmentUploadDragAndDropWrapper';
AttachmentUploadDragAndDropWrapper.propTypes = {
  children: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  ignoreDropWhenDefaultPrevented: PropTypes.bool.isRequired,
  onDropFiles: PropTypes.func.isRequired
};
AttachmentUploadDragAndDropWrapper.defaultProps = {
  disabled: false,
  ignoreDropWhenDefaultPrevented: true
};
export default AttachmentUploadDragAndDropWrapper;